import React from "react";

export default function EmbeddedContactPage() {
  return (
    <div className="h-full w-full">
      <iframe
        className="h-full w-full"
        title="Contact Page"
        src="https://neutrinodesign.typeform.com/to/Nkk0Oalg"
      />
    </div>
  );
}
