import React, { useState } from "react";

const MortgageCalculator = () => {
  const [purchasePrice, setPurchasePrice] = useState(1500000);
  const [downPayment, setDownPayment] = useState(20);
  const [loanTerm, setLoanTerm] = useState(30);
  const [interestRate, setInterestRate] = useState(3.5);

  const calculateMonthlyPayment = () => {
    const loanAmount = purchasePrice - (downPayment / 100) * purchasePrice;
    const monthlyRate = interestRate / 100 / 12;
    const numberOfPayments = loanTerm * 12;
    return (
      (loanAmount * monthlyRate) /
      (1 - Math.pow(1 + monthlyRate, -numberOfPayments))
    ).toFixed(2);
  };

  return (
    <div className="p-4 font-sans max-w-xs">

      <h2 className="font-bold text-xl mb-4">Mortgage Calculator</h2>

      <div className="mb-4">
        <label className="block text-gray-500 text-xs">Purchase Price</label>
        <span className="block mb-2 text-lg font-bold">${purchasePrice.toLocaleString()}</span>
        <input
          type="range"
          min="300000"
          max="20000000"
          step="10000"
          value={purchasePrice}
          onChange={(e) => setPurchasePrice(Number(e.target.value))}
          className="w-full h-[1px] accent-[#639bbf] rounded-full"
        />
        <div className="flex text-xs text-gray-500 justify-between">
          <span>$300,000</span>
          <span>$20M</span>
        </div>
      </div>

      <div className="mb-4">
        <label className="block text-gray-500 text-xs">Down Payment</label>
        <div className="flex items-center space-x-2 mb-2">
          <span className="font-bold text-lg">
            {downPayment}%
          </span>
          <span className="text-xs text-gray-500">
            ${((downPayment / 100) * purchasePrice).toLocaleString()}
          </span>
        </div>
        <input
          type="range"
          min="3"
          max="99"
          step="1"
          value={downPayment}
          onChange={(e) => setDownPayment(Number(e.target.value))}
          className="w-full h-[1px] accent-[#639bbf] rounded-full"
        />
        <div className="flex justify-between text-gray-500 text-xs">
          <span>3%</span>
          <span>99%</span>
        </div>
      </div>

      <div className="mb-4">
        <label className="block text-gray-500 text-xs">Loan Type</label>
        <div className="flex flex-col">
          <label className="flex items-center font-bold mb-2">
            <input
              type="radio"
              name="loanType"
              checked={loanTerm === 30}
              onChange={() => setLoanTerm(30)}
              className="mr-2"
            />
            30-year fixed
          </label>
          <label className="flex items-center font-bold mb-2">
            <input
              type="radio"
              name="loanType"
              checked={loanTerm === 15}
              onChange={() => setLoanTerm(15)}
              className="mr-2"
            />
            15-year fixed
          </label>
          <label className="flex items-center font-bold">
            <input
              type="radio"
              name="loanType"
              checked={loanTerm === 5}
              onChange={() => setLoanTerm(5)}
              className="mr-2"
            />
            5/1 ARM variable
          </label>
        </div>
      </div>

      <div className="mt-4">
        <label className="block text-gray-500 text-xs">Estimated Monthly Payment</label>
        <p className="text-lg font-bold text-[#639bbf]">
          ~${calculateMonthlyPayment()}
        </p>
      </div>
    </div>
  );
};

export default MortgageCalculator;
