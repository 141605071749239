import { IoMdSearch } from "react-icons/io";
import { SimpleDropdownMenu } from "./commonElements/SimpleDropdownMenu";
import { SimpleChip } from "./commonElements/simpleChip";
import { find } from "lodash";

const propertyTypeOptions = [
  { id: 0, label: "Purchase", name: "buy" },
  { id: 1, label: "Rent", name: "rent" },
];

const bedroomOptions = [
  { id: 1, label: "1", name: "1-any" },
  { id: 2, label: "2", name: "2-any" },
  { id: 3, label: "3", name: "3-any" },
  { id: 4, label: "4", name: "4-any" },
  { id: 5, label: "5+", name: "5-any" },
];

const bathroomOptions = [
  { id: 1, label: "1", name: "1-any" },
  { id: 2, label: "2", name: "2-any" },
  { id: 3, label: "3", name: "3-any" },
  { id: 4, label: "4", name: "4-any" },
  { id: 5, label: "5+", name: "5-any" },
];

const squareFeetOptions = [
  { id: 1, label: "Up to 1000 sqft", name: "1-1000" },
  { id: 2, label: "1000 sqft - 2000 sqft", name: "1000-2000" },
  { id: 3, label: "2000 sqft - 4000 sqft", name: "2000-4000" },
  { id: 4, label: "4000 sqft - 6000 sqft", name: "4000-6000" },
  { id: 5, label: "6000 sqft +", name: "6000-any" },
];

export const Filters = (props) => {
  const { searchFilters, setSearchFilters, setActivePage } = props;
  // const [searchQuery, setSearchQuery] = useState("");

  const listingPrimaryFiltersArray = [
    {
      id: "primary-filter-1",
      label: "Type",
      name: "propertyType",
      options: propertyTypeOptions,
      selectedOption: find(propertyTypeOptions, {
        name: searchFilters.propertyType,
      }),
    },
    {
      id: "primary-filter-2",
      label: "Sqft",
      name: "squareFeet",
      options: squareFeetOptions,
      selectedOption: find(squareFeetOptions, {
        name: searchFilters.squareFeet.value,
      }),
    },
    {
      id: "primary-filter-3",
      label: "Bath",
      name: "bathrooms",
      options: bathroomOptions,
      selectedOption: find(bathroomOptions, {
        name: searchFilters.bathrooms.value,
      }),
    },
    {
      id: "primary-filter-4",
      label: "Bed",
      name: "bedrooms",
      options: bedroomOptions,
      selectedOption: find(bedroomOptions, {
        name: searchFilters.bedrooms.value,
      }),
    },
  ];

  const handleOptionSelect = (filterProps) => {
    const { optionName, optionValue } = filterProps;
    const simpleFilter = optionName === "propertyType";
    const rangeFilter =
      optionName === "bedrooms" ||
      optionName === "bathrooms" ||
      optionName === "budget" ||
      optionName === "squareFeet";
    if (simpleFilter) {
      setSearchFilters((prevFilters) => ({
        ...prevFilters,
        [optionName]: optionValue,
      }));
    } else if (rangeFilter) {
      const [min, max] =
        optionValue === "any" ? ["any", "any"] : optionValue.split("-");
      setSearchFilters((prevFilters) => ({
        ...prevFilters,
        [optionName]: { min: min, max: max, value: optionValue },
      }));
    }
    setActivePage(1);
  };

  const handleChipClose = (optionName) => {
    handleOptionSelect({
      optionName: optionName,
      optionValue: "any",
    });
  };

  return (
    <div>
      <div className="flex flex-wrap items-center">
        <div className="w-full md:w-auto relative rounded-md shadow-sm mt-2 md:mt-2">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <IoMdSearch className="text-gray-500" />
          </div>
          <input
            type="text"
            className="pl-8 block w-full rounded-md border-0 py-1 sm:py-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 text-sm sm:text-base"
            placeholder="Search..."
          />
        </div>

        <div className="grid grid-cols-3 md:flex">
          {listingPrimaryFiltersArray.map((primaryFilter) => (
            <div key={primaryFilter.id} className="ml-3 mt-5 md:mt-2">
              <SimpleDropdownMenu
                label={primaryFilter.label}
                name={primaryFilter.name}
                selectedOption={primaryFilter.selectedOption}
                options={primaryFilter.options}
                handleOptionSelect={handleOptionSelect}
              />
            </div>
          ))}
          <div className="ml-3 mt-5 md:mt-2">
            <SimpleDropdownMenu
              label="..."
              name="other"
              labelTopPosition={false}
              hasAnyOption={false}
              // options={propertyTypeOptions}
              // handleOptionSelect={handleOptionSelect}
            />
          </div>
        </div>
      </div>
      <div className="flex">
        {listingPrimaryFiltersArray.map(
          (primaryFilter) =>
            primaryFilter.selectedOption && (
              <div key={primaryFilter.id + "chip"} className="mt-5 mr-2">
                <SimpleChip
                  id={primaryFilter.name}
                  children={
                    primaryFilter.label +
                    ": " +
                    primaryFilter.selectedOption.label
                  }
                  onClose={handleChipClose}
                />
              </div>
            )
        )}
        {/* <div className="my-5 mr-2">
          <SimpleChip children="Budget: $1.5M - $3.5M" />
        </div> */}
      </div>
    </div>
  );
};
