import React from "react";
import { Marker, Popup } from "react-leaflet";
import L from "leaflet";

const CustomMarker = (props) => {
  const {
    position,
    price,
    propertyType,
    bedrooms,
    bathrooms,
    sqft,
    imageUrl,
    onShowDetail,
    id,
    isOnHomePage, // New prop to indicate if the marker is on the home page
    filterParametersString,
    highlighted,
  } = props;
  const buildingTypes = [
    "Condominium",
    "Multi Family",
    "Industrial",
  ];
  const iconClass = buildingTypes.includes(propertyType) ? "fa-building" : "fa-home";

  const customMarkerHtml = `
  <div style="
    background-color: ${highlighted ? "black" : "#639bbf"};
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 8px;
    padding: 8px;
  ">
    <i class="fa ${iconClass}" aria-hidden="true" style="margin-right: 8px;"></i>
    <span style="font-weight: 600;">$${abbreviatePrice(price)}</span>
  </div>
`;

  const customIcon = L.divIcon({
    className: "custom-div-icon",
    html: customMarkerHtml,
    iconSize: [120, 60],
    iconAnchor: [60, 30],
    popupAnchor: [0, -30],
  });

  function abbreviatePrice(value) {
    var newValue = value;
    if (value >= 1000 && value < 1000000) {
      newValue = (value / 1000).toFixed(1) + "K";
    } else if (value >= 1000000) {
      newValue = (value / 1000000).toFixed(1) + "M";
    }
    return newValue.toString();
  }

  function formatNumber(value) {
    return value.toLocaleString();
  }

  return (
    <Marker position={position} icon={customIcon}>
      <Popup>
        <div className="w-64">
          <img
            src={imageUrl}
            alt="Property"
            className="w-75 h-50 object-cover rounded-t-lg"
          />
          {/* Additional div for content with margin */}
          <div className="m-2.5">
            <div
              className="text-center text-[1.2em]"
            >
              <h3 className="m-0">${formatNumber(price)}</h3>
              <p style={{ marginTop: "0px" }}>
                <strong>{bedrooms}</strong> bd | <strong>{bathrooms}</strong> ba
                | <strong>{sqft}</strong> sqft
              </p>
              <button
                className="bg-[#639bbf] hover:bg-[#02568a] text-white border-none py-2 px-4 rounded cursor-pointer w-full font-semibold"
                onClick={() => {
                  if (isOnHomePage) {
                    // Navigate to BrowsePage with the selected property ID
                    window.location.href = `/browse?propertyId=${id}${filterParametersString}`;
                  } else {
                    onShowDetail();
                  }
                }}
              >
                View Listing
              </button>
            </div>
          </div>
        </div>
      </Popup>
    </Marker>
  );
};

export default CustomMarker;
