import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import neighborhoodsData from "../../Assets/neighborhoods.json";

const Neighborhoods = () => {
  const [neighborhoods] = useState(neighborhoodsData);
  const navigate = useNavigate();

  const handleNeighborhoodClick = (neighborhood) => {
    navigate(`/neighborhood/${neighborhood.id}`);
  };

  return (
    <div className="py-2 md:py-5 pb-0 md:pb-0 px-2 md:px-5">
      <div className="p-2 md:p-5 pb-0 md:pb-0 bg-white rounded-lg drop-shadow-sm">
        <div className="container mx-auto p-6">
          <h1 className="text-3xl font-bold mb-6">Neighborhoods</h1>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
            {neighborhoods.map((neighborhood) => (
              <div
                key={neighborhood.id}
                className="relative cursor-pointer overflow-hidden rounded-lg shadow-lg group"
                onClick={() => handleNeighborhoodClick(neighborhood)}
              >
                <img
                  src={neighborhood.image}
                  alt={neighborhood.title}
                  className="w-full h-full object-cover transform scale-105 transition-transform duration-300"
                />
                <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 opacity-100 transition-opacity duration-300">
                  <div className="absolute inset-x-0 bottom-0 p-4 text-white">
                    <h3 className="text-2xl font-bold">{neighborhood.title}</h3>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Neighborhoods;
