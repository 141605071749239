import React, { useEffect, useState } from "react";
import "leaflet/dist/leaflet.css"; // Import Leaflet CSS
import MapView from "../components/MapView"; // Adjust the path based on your file structure
import PageFilters from "../components/PageFilters";
import usePropertyList from "../hooks/usePropertyList";
import { getListings } from "../Api";
import { getInitialFilterValues } from "../Utils";
import MortgageCalculator from "../components/MortgageCalculator"; // Import Mortgage Calculator
import { FaCalculator, FaTimes } from "react-icons/fa"; // Import an icon from react-icons (or use any other icon library)

const HomePage = () => {
  const { isLoading, setIsLoading } = usePropertyList("buy");
  const [searchFilters, setSearchFilters] = useState(
    getInitialFilterValues("buy")
  );
  const [mapMarkersList, setMapMarkersList] = useState([]);
  const [isCalculatorOpen, setIsCalculatorOpen] = useState(false); // State to toggle the calculator

  useEffect(() => {
    fetchMarkers();
  }, []);

  const fetchMarkers = async () => {
    setIsLoading(true);
    await getListings(searchFilters, "&limit=200")
      .then((response) => setMapMarkersList(response.properties))
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return (
      <>
        <div className="center-container">
          <div className="loading-animation"></div>
        </div>
      </>
    );
  }

  return (
    <div className="grid grid-cols-2 h-full">
      <div className="flex-1.5 p-5 flex flex-col justify-center text-left overflow-y-auto">
        <h1 className="font-bold max-sm:text-3xl mb-0">
          The Miami Residences.
        </h1>
        <p className="max-sm:text-lg md:text-2xl">
          The premiere platform for procuring luxury real estate in Miami.
        </p>
        {/* Pass the new handlers as props to PageFilters */}
        <PageFilters
          searchFilters={searchFilters}
          setSearchFilters={setSearchFilters}
          handleFilterApply={() => fetchMarkers()}
        />
      </div>
      <div className="relative">
        {/* Pass the propertyType and filters state as props to MapView */}
        <MapView
          searchFilters={searchFilters}
          mapMarkersList={mapMarkersList}
          isOnHomePage={true}
        />
        <div
          style={{
            position: "absolute",
            bottom: "20px",
            right: "20px",
            zIndex: 500,
          }}
        >
          <button
            className={`bg-[#639bbf] absolute bottom-5 right-5 text-white p-3 rounded-full shadow-lg flex items-center justify-center`}
            onClick={() => setIsCalculatorOpen(!isCalculatorOpen)}
          >
            {isCalculatorOpen ? (
              <FaTimes size={24} />
            ) : (
              <FaCalculator size={24} />
            )}
          </button>

          {isCalculatorOpen && (
            <div className="absolute bottom-20 right-2 bg-white p-4 rounded-lg shadow-lg w-96">
              <MortgageCalculator />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomePage;
