import React, { useState, useRef, useEffect } from "react";
import { IoChevronDown } from "react-icons/io5";

export const SimpleDropdownMenu = (props) => {
  const {
    name = "",
    label = "",
    selectedOption = { id: 0, name: "any", label: "Any" },
    labelTopPosition = true,
    hasAnyOption = true,
    options = [],
    handleOptionSelect = () => {},
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (isOpen && dropdownRef.current) {
      const rect = dropdownRef.current.getBoundingClientRect();
      if (rect.right > window.innerWidth) {
        dropdownRef.current.style.left = "auto";
        dropdownRef.current.style.right = "0";
      } else {
        dropdownRef.current.style.left = "0";
        dropdownRef.current.style.right = "auto";
      }
    }
  }, [isOpen]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className="relative inline-block min-w-24 md:min-w-28"
      ref={dropdownRef}
    >
      <div
        onClick={toggleDropdown}
        className="flex justify-between items-center p-1 sm:p-2 border-0 ring-1 ring-inset ring-gray-300 rounded-md drop-shadow-md text-sm sm:text-base shadow-sm"
      >
        {labelTopPosition ? (
          <>
            <div
              className="absolute font-bold text-sm"
              style={{ top: -18, left: 2 }}
            >
              {label}
            </div>
            {selectedOption.label}
          </>
        ) : (
          label
        )}
        <IoChevronDown />
      </div>
      {isOpen && (
        <div className="absolute left-0 mt-2 w-48 z-10 bg-white border border-gray-200 rounded-md shadow-lg">
          {hasAnyOption && (
            <div
              className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
              onClick={() =>
                handleOptionSelect({
                  optionName: name,
                  optionValue: "any",
                  optionId: 0,
                })
              }
            >
              Any
            </div>
          )}
          {options.map((option) => (
            <div
              key={option.name + options.id}
              className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
              onClick={() =>
                handleOptionSelect({
                  optionName: name,
                  optionValue: option.name,
                  optionId: option.id,
                })
              }
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
