import { useState } from "react";

const usePropertyList = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  return {
    isLoading,
    setIsLoading
  };
};

export default usePropertyList;
