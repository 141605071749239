import { RxCrossCircled } from "react-icons/rx";

export const SimpleChip = (props) => {
  const {
    id = "",
    backgroundColor = "#649abc",
    color = "white",
    children = "No Text",
    onClose = () => {},
  } = props;
  return (
    <div
      className="flex items-center bg-[var(backgroundColor)] color-[var(color)] text-gray-800 rounded-md p-1 sm:p-2 pace-x-2 max-w-max drop-shadow-md "
      style={{
        color,
        backgroundColor,
      }}
    >
      <span className="text-xs sm:text-sm flex-1">{children}</span>
      <button
        className="color-[var(color)] hover:text-gray-800 focus:outline-none ml-2"
        style={{
          color,
        }}
        onClick={() => onClose(id)}
      >
        <RxCrossCircled />
      </button>
    </div>
  );
};
