import React from "react";

function SimpleModalWrapper(props) {
  const { children, onClose } = props;

  return (
    <div
      className="fixed top-[-74px] lg:top-[-84px] left-[-8px] lg:left-[-20px] h-screen w-screen lg:w-screen lg:h-screen flex justify-center items-center z-[1000] bg-gray-600/50 overflow-hidden"
      onClick={() => onClose()}
    >
      <div
        className="h-4/5 w-full lg:w-3/5 flex items-center rounded-md p-2 bg-white"
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
}

export default SimpleModalWrapper;
