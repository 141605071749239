/* eslint-disable react-hooks/exhaustive-deps */
// useListings.js
import { useCallback, useEffect, useState } from "react";
import usePropertyList from "../hooks/usePropertyList";
import { getListings } from "../Api";
import { debounce } from "lodash";

const useListings = (props) => {
  const { searchFilters, queryParams, activePage, limit = 50 } = props;
  const { isLoading, setIsLoading } = usePropertyList();
  const [mapMarkersList, setMapMarkersList] = useState([]);
  const [totalResults, setTotalResults] = useState(0);

  // Debounced API call
  const debouncedApiCall = useCallback(
    debounce(async ({ querySearchFilters, additionalParams }) => {
      try {
        await getListings(
          querySearchFilters,
          `${additionalParams}&limit=${limit}${getOffsetRange()}`
        )
          .then((response) => {
            setMapMarkersList(response.properties);
            setTotalResults(response.total);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    }, 600), // Adjust the delay as needed (500ms in this case)
    []
  );

  useEffect(() => {
    setIsLoading(true);
    debouncedApiCall({
      querySearchFilters: searchFilters,
      additionalParams: queryParams,
    });
    // Cleanup function to cancel debounced calls on unmount
    return () => {
      debouncedApiCall.cancel();
    };
  }, [searchFilters, activePage, queryParams, debouncedApiCall]);

  const getOffsetRange = () => {
    if (activePage === 1) return "";
    else {
      const val = (activePage - 1) * 50;
      return "&offset=" + val;
    }
  };

  return { isLoading, mapMarkersList, totalResults };
};

export default useListings;
