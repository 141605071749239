import { useState } from "react";
import { FiSearch } from "react-icons/fi";

export const MapFilters = (props) => {
  const { searchFilters, setSearchFilters } = props;
  const [searchQuery, setSearchQuery] = useState("");

  const handleFilterChange = (filterName, value) => {
    if (filterName === "type") {
      setSearchFilters((prevFilters) => ({
        ...prevFilters,
        [filterName]: value,
      }));
    } else {
      const [min, max] = value.split("-");
      setSearchFilters((prevFilters) => ({
        ...prevFilters,
        [filterName]: { min: min, max: max, value: value },
      }));
    }
  };

  return (
    <div className="absolute top-2 lg:top-6 left-12 lg:left-20 z-50">
      <div className="flex w-full mb-2.5">
        <FiSearch className="absolute m-3 z-10 text-gray-400" />
        <input
          type="text"
          className="w-full py-2.5 pr-2.5 pl-10 border-none rounded-lg text-base"
          placeholder="Search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      <div className="flex gap-1 lg:gap-4">
        <div className="bg-white rounded-lg border border-gray-300 p-1.5">
          <div className="font-semibold text-xs lg:text-sm pl-0.5">Property Type</div>
          <select
            value={searchFilters.propertyType}
            onChange={(e) => handleFilterChange("type", e.target.value)}
            className="text-xs lg:text-sm"
          >
            <option value="buy">Buy</option>
            <option value="rent">Rent</option>
          </select>
        </div>
        <div className="bg-white rounded-lg border border-gray-300 p-1.5">
          <div className="font-semibold text-xs lg:text-sm pl-0.5">Min Bedrooms</div>
          <select
            value={searchFilters.bedrooms.value}
            onChange={(e) => handleFilterChange("bedrooms", e.target.value)}
            className="text-xs lg:text-sm"
          >
            <option value="any-any">Any</option>
            <option value="1-any">1</option>
            <option value="2-any">2</option>
            <option value="3-any">3</option>
            <option value="4-any">4</option>
            <option value="5-any">5+</option>
          </select>
        </div>
        <div className="bg-white rounded-lg border border-gray-300 p-1.5">
          <div className="font-semibold text-xs lg:text-sm pl-0.5">Min Bathrooms</div>
          <select
            value={searchFilters.bathrooms.value}
            onChange={(e) => handleFilterChange("bathrooms", e.target.value)}
            className="text-xs lg:text-sm"
          >
            <option value="any-any">Any</option>
            <option value="1-any">1</option>
            <option value="2-any">2</option>
            <option value="3-any">3</option>
            <option value="4-any">4</option>
            <option value="5-any">5+</option>
          </select>
        </div>
      </div>
    </div>
  );
};
