import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import "./App.css";
import HomePage from "./Pages/HomePage";
import Neighborhoods from "./Pages/Neighborhoods";
import NeighborhoodDetail from "./Pages/Neighborhoods/NeighborhoodDetail";
import NavigationBar from "./components/NavigationBar";
import BrowsePage from "./Pages/BrowsePage";
import LuxuryBuildings from "./Pages/LuxuryBuildings";
import NewConstruction from "./Pages/NewConstruction";
import OpenHouses from "./Pages/OpenHouses";
import LoginPage from "./Pages/LoginPage";

function App() {
  return (
    <Auth0Provider
      domain={`${process.env.REACT_APP_YOUR_AUTH0_DOMAIN}`}
      clientId={`${process.env.REACT_APP_YOUR_AUTH0_CLIENT_ID}`}
      redirectUri={window.location.origin}
    >
      <Router>
        <NavigationBar />
        <div className="h-[calc(100vh-65px)]">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/browse" element={<BrowsePage />} />{" "}
            <Route path="/luxury-buildings" element={<LuxuryBuildings />} />{" "}
            <Route path="/neighborhoods" element={<Neighborhoods />} />{" "}
            <Route path="/neighborhood/:id" element={<NeighborhoodDetail />} />{" "}
            <Route path="/new-construction" element={<NewConstruction />} />{" "}
            <Route path="/open-houses" element={<OpenHouses />} />{" "}
            <Route path="/login" element={<LoginPage />} />{" "}
          </Routes>
        </div>
      </Router>
    </Auth0Provider>
  );
}

export default App;
