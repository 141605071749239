import {range} from 'lodash'
export const PropertiesPagination = (props) => {
  const {totalResults, activePage, setActivePage, } = props

  const getPaginationButtons = () => {
    const pagesNumber = Math.ceil(totalResults/50)
    return range(1, pagesNumber+1)
  }

  const handlePrevPage = () => {
    if(activePage !== 1) setActivePage(activePage -1)
  }

  const handleNextPage = () => {
    if(activePage !== getPaginationButtons()) setActivePage(activePage + 1)
  }

  const lastElement = () => {
    return getPaginationButtons().length
  }

  const pageRenderCondition = (pageNumber) => {
    return (pageNumber === activePage || pageNumber === activePage + 1 || pageNumber === activePage - 1) && pageNumber !== 1 && pageNumber !== lastElement()
  }

  return (
    <div className="h-24 flex justify-center items-center">
      <div className="h-full w-auto flex justify-center items-center">

        <button style={styles.paginationButton} onClick={handlePrevPage}>Prev</button>

        <button
          style={{...styles.paginationButton,  ...(activePage === 1 && styles.activePaginationButton)}}
          onClick={() => setActivePage(1)}
        >
          1
        </button>

        {
          (activePage !== 1 && activePage !== 2)
          && "....."

        }

        {
          getPaginationButtons().map((pageNumber) => {
            if(pageRenderCondition(pageNumber))
            return (
              <button
                key={"pagination-button" + pageNumber}
                style={{...styles.paginationButton,  ...(activePage === pageNumber && styles.activePaginationButton)}}
                onClick={() => setActivePage(pageNumber)}
              >
                {pageNumber}
              </button>
            )
          })
        }

        {
          (activePage !== lastElement() && activePage !== lastElement() - 1 )
          && "....."

        }

        {
          totalResults > 50 &&
          <button
            style={{...styles.paginationButton,  ...(activePage === lastElement() && styles.activePaginationButton)}}
            onClick={() => setActivePage(lastElement())}
          >
            {lastElement()}
          </button>
        }

        <button onClick={handleNextPage} style={styles.paginationButton}>Next</button>
      </div>
    </div>
  )
}

const styles = {
  paginationButton: {
    height: 50,
    width: 50,
    margin: '0px 10px',
    borderRadius: 5,
    border: '1px solid #ccc',
    cursor: 'pointer'
  },
  activePaginationButton: {
    height: 50,
    width: 50,
    margin: '0px 10px',
    backgroundColor: '#097bff',
    color: 'white'
  }
}