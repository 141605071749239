import React from "react";
import { NavLink } from "react-router-dom"; // Import NavLink for navigation
import { useAuth0 } from "@auth0/auth0-react"; // Import useAuth0 hook
import Logo from "../Assets/img/MR-Temp-Logo.svg"; // Adjust the path as necessary

const NavigationBar = () => {
  const { loginWithRedirect, logout, isAuthenticated, user } = useAuth0();

  return (
    <div className="flex justify-between px-5 items-center bg-[#1d2939] h-16">
      <div>
        <NavLink to="/" className="font-bold text-[#639bbf] no-underline">
          <img src={Logo} alt="MR Logo" style={{ height: "40px" }} />
        </NavLink>
      </div>
      <div className="hidden md:block text-white">
        <NavLink
          className="mx-2"
          to="/luxury-buildings"
          activeclassname="text-blue-500 font-semibold"
        >
          Luxury Buildings
        </NavLink>
        <NavLink
          className="mx-2"
          to="/neighborhoods"
          activeclassname="text-blue-500 font-semibold"
        >
          Neighborhoods
        </NavLink>
        <NavLink
          className="mx-2"
          to="/new-construction"
          activeclassname="text-blue-500 font-semibold"
        >
          New Construction
        </NavLink>
        <NavLink
          className="mx-2"
          to="/open-houses"
          activeclassname="text-blue-500 font-semibold"
        >
          Open Houses
        </NavLink>
      </div>
      <div className="flex gap-8 font-medium text-sm">
        {!isAuthenticated ? (
          <button
            onClick={() => loginWithRedirect()}
            className="bg-[#639bbf] px-2 py-2.5 border-none text-white cursor-pointer font-semibold no-underline rounded-md hover:bg-[#02568a]"
          >
            Login/Sign Up
          </button>
        ) : (
          <>
            <span
              className="text-white font-semibold cursor-default text-xs md:hidden lg:block mt-3"
            >{`${user.name}`}</span>
            <button
              onClick={() => logout({ returnTo: window.location.origin })}
              className="bg-red-500 px-2 py-2.5 border-none text-white cursor-pointer font-semibold no-underline rounded-md hover:bg-red-700"
            >
              Logout
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default NavigationBar;
