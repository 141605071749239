/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { FaRegCirclePause, FaRegCirclePlay } from "react-icons/fa6";
import { GrExpand } from "react-icons/gr";
import SimpleModalWrapper from "../modals/simpleModal/SimpleModalWrapper";

export const Carousel = (props) => {
  const { images = [] } = props;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imageModal, setImageModal] = useState(false);
  // State to manage whether the interval is running
  const [isRunning, setIsRunning] = useState(false);
  // Ref to store interval ID
  const intervalRef = useRef(null);

  // Effect to manage interval based on isRunning state
  useEffect(() => {
    if (isRunning) {
      startInterval();
    } else {
      stopInterval();
    }
    // Cleanup on component unmount or when interval changes
    return () => {
      stopInterval();
    };
  }, [isRunning]);

  const goToSlide = (e, index) => {
    setCurrentIndex(index);
  };

  // Function to start the interval
  const startInterval = () => {
    if (intervalRef.current) return; // Prevent multiple intervals
    intervalRef.current = setInterval(() => {
      setCurrentIndex((prevCount) =>
        prevCount === images.length - 1 ? 0 : prevCount + 1
      ); // Increment count
    }, 2000); // Update every second
  };

  // Function to stop the interval
  const stopInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  // Event handler to toggle the interval
  const handleToggleInterval = () => {
    setIsRunning((prevIsRunning) => !prevIsRunning);
  };

  // const handleExpandImage = () => {

  // }

  return (
    <div
      className="h-full relative w-full max-w-lg mx-auto"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="h-full overflow-hidden relative rounded-lg">
        <img
          src={images[currentIndex]?.MediaURL}
          alt={`Slide ${currentIndex + 1}`}
          className="w-full h-full object-cover"
        />
      </div>
      <div className="absolute bottom-0 left-0 right-0 h-10 flex px-3 justify-between items-center text-white bg-gray-800/70 rounded-b-md">
        {isRunning ? (
          <FaRegCirclePause
            className="text-xs sm:text-base cursor-pointer"
            onClick={handleToggleInterval}
          />
        ) : (
          <FaRegCirclePlay
            className="text-xs sm:text-base cursor-pointer"
            onClick={handleToggleInterval}
          />
        )}
        <div className="flex">
          {images.slice(0, 7).map((_, index) => (
            <div
              key={index}
              onClick={(e) => goToSlide(e, index)}
              className={`h-1 md:h-2 w-1 md:w-2 rounded-full mx-1 md:mx-2 cursor-pointer ${
                currentIndex === index ? "bg-cyan-500" : "bg-white"
              }`}
            ></div>
          ))}
        </div>
        <GrExpand
          className="text-xs sm:text-base cursor-pointer"
          onClick={() => setImageModal(true)}
        />
        {imageModal && (
          <SimpleModalWrapper onClose={() => setImageModal(false)}>
            <img
              src={images[currentIndex].MediaURL}
              alt={`Slide ${currentIndex + 1}`}
              className="w-full h-full object-cover"
            />
          </SimpleModalWrapper>
        )}
      </div>
    </div>
  );
};
