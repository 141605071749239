/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import MapView from "../components/MapView";
import "./BrowsePage.css";
import usePropertyList from "../hooks/usePropertyList";
import { getListings } from "../Api";
import { filterParametersString, getInitialFilterValues } from "../Utils";
import { MapFilters } from "../components/MapFilters";
import PropertyDetailsModal from "../components/modals/propertyDetailsModal";
import { toInteger } from "lodash";
import neighborhoodsData from "../Assets/neighborhoods.json";
import { findTopThreeClosestLocations } from "../Utils/closest-distance";

const BrowsePage = () => {
  const { isLoading, setIsLoading } = usePropertyList();

  const [searchFilters, setSearchFilters] = useState(getInitialFilterValues());
  const [mapMarkersList, setMapMarkersList] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [map, setMap] = useState(null);

  const searchParams = new URLSearchParams(window.location.search);
  const propertyId = searchParams.get("propertyId");
  const activePageParam = searchParams.get("activePage")
    ? searchParams.get("activePage")
    : 1;
  const newConstructionParam = searchParams.get("new-construction")
    ? searchParams.get("new-construction")
    : "";
  const luxuryParam = searchParams.get("luxury")
    ? searchParams.get("luxury")
    : "";
  const openHousesParam = searchParams.get("open-houses")
    ? searchParams.get("open-houses")
    : "";
  const neighborhoodParam = searchParams.get("neighborhood")
    ? searchParams.get("neighborhood")
    : "";

  useEffect(() => {
    fetchMarkers();
  }, [searchFilters]);

  useEffect(() => {
    if (propertyId && mapMarkersList?.length > 0) {
      onShowDetail(propertyId);
    }
  }, [propertyId, mapMarkersList]);

  const fetchMarkers = async () => {
    setIsLoading(true);
    await getListings(searchFilters, getParams())
      .then((response) => setMapMarkersList(response.properties))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const closeModal = () => {
    setSelectedProperty(null);
  };

  const getParams = () => {
    const newConstructionAttr =
      newConstructionParam !== "" ? "NewConstructionYN.eq=true" : "";
    const luxuryAttr = luxuryParam !== "" ? "ListPrice.gte=1000000" : "";
    const openHousesAttr =
      openHousesParam !== "" ? "MIAMIRE_ShowingTimeFlag.eq=true" : "";
    const neighborhoodAttr =
      neighborhoodParam !== ""
        ? `SubdivisionName.eq=${
            neighborhoodsData[neighborhoodParam]?.title || ""
          }`
        : "";
    const limitAttr = "&limit=200";
    return (
      newConstructionAttr +
      luxuryAttr +
      openHousesAttr +
      neighborhoodAttr +
      limitAttr +
      getOffsetRange()
    );
  };

  const getOffsetRange = () => {
    if (toInteger(activePageParam) === 1) return "";
    else {
      const val = (activePageParam - 1) * 50;
      return "&offset=" + val;
    }
  };

  const onShowDetail = (propertyId) => {
    const property = mapMarkersList.find((prop) => prop.id === propertyId);
    setSelectedProperty(property);
  };

  if (isLoading) {
    return (
      <div className="flex flex-col justify-center items-center text-center min-h-[calc(97vh-120px)]">
        <div className="loading-animation"></div>
      </div>
    );
  }

  return (
    <div>
      <div className="relative h-[calc(100vh-64px)]">
        <MapFilters
          searchFilters={searchFilters}
          setSearchFilters={setSearchFilters}
        />
        <MapView
          searchFilters={searchFilters}
          isOnHomePage={false}
          mapMarkersList={mapMarkersList}
          setSelectedProperty={setSelectedProperty}
          selectedProperty={selectedProperty}
          onShowDetail={onShowDetail}
          map={map}
          setMap={setMap}
        />
      </div>
      {selectedProperty && (
        <PropertyDetailsModal
          filterParametersString={filterParametersString(searchFilters)}
          searchFilters={searchFilters}
          property={selectedProperty}
          onClose={closeModal}
          similarProperties={findTopThreeClosestLocations(
            selectedProperty,
            mapMarkersList
          )}
        />
      )}
    </div>
  );
};

export default BrowsePage;
