import { CiLocationOn } from "react-icons/ci";
import { TfiRulerAlt2 } from "react-icons/tfi";
import { MdOutlineKingBed } from "react-icons/md";
import { FaShower } from "react-icons/fa";
import { useState } from "react";
import { Carousel } from "../../commonElements/carousel";
import { numberWithCommas } from "../../../Utils";

export const PropertyDetailsComponent = (props) => {
  const { property, showContactForm } = props;
  const [showDetailedDescription, setShowDetailedDescription] = useState(false);

  return (
    <div className="h-full flex flex-col md:flex-row pb-5">
      <div className="w-full md:w-2/5 h-full">
        {property.images.length > 0 ? (
          <Carousel images={property.images} />
        ) : property.imageUrl ? (
          <img
            className="w-full h-full rounded-md object-none"
            src={property.imageUrl}
            alt="Property"
          />
        ) : (
          "No images available"
        )}
      </div>
      <div className="grid grid-rows-7 grid-flow-col w-full md:w-3/5 px-6">
        <div className="w-full row-span-6 md:row-span-5">
          <div>
            <div className="text-xs md:text-sm lg:text-lg font-semibold py-1 pb-0">
              {property.name}
            </div>
            <div className="flex items-start text-gray-500 py-1">
              <CiLocationOn className="mr-1 mt-1 w-6" />
              <span className="text-xs md:text-sm">{property.address}</span>
            </div>
            <div className="flex items-center py-0 lg:py-2">
              <div className="flex items-center">
                <TfiRulerAlt2 className="text-xs md:text-2xl text-cyan-500 " />
                <div className="ml-2 text-xs md:text-md font-semibold">
                  {property.squareFeet}
                </div>
              </div>
              <div className="flex items-center mx-2">
                <MdOutlineKingBed className="text-xs md:text-2xl text-cyan-500" />
                <div className="ml-2 text-xs md:text-md font-semibold">
                  {property.bedrooms}
                </div>
              </div>
              <div className="flex items-center mx-2">
                <FaShower className="text-xs md:text-2xl text-cyan-500" />
                <div className="ml-2 text-xs md:text-md font-semibold">
                  {property.bathrooms}
                </div>
              </div>
            </div>
            <div className="overflow-auto">
              <p
                className="pt-5 text-xs overflow-auto line-clamp-[var(lineClamp)]"
                style={{ lineClamp: showDetailedDescription ? "none" : "6" }}
              >
                {property.description}{" "}
                {!showDetailedDescription && (
                  <span
                    className="text-cyan-500 cursor-pointer"
                    onClick={() => setShowDetailedDescription(true)}
                  >
                    View Detail
                  </span>
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="row-span-1 md:row-span-2 flex flex-wrap items-end">
          <div className="text-xs md:text-sm">
            Asking price:{" "}
            <span className="text-xs md:text-2xl font-bold">
              ${numberWithCommas(property.price)}
            </span>
          </div>
          <div
            className=" text-white hover:bg-#02568a bg-[#639bbf] p-2 md:p-5 rounded-md cursor-pointer w-full font-light md:font-semibold text-center h-8 md:h-16 text-xs md:text-base"
            onClick={showContactForm}
          >
            Schedule a Showing
          </div>
        </div>
      </div>
    </div>
  );
};
