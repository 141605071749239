import { MdOutlineKingBed } from "react-icons/md";
import { numberWithCommas } from "../Utils";
import { FaShower } from "react-icons/fa";
import { CiLocationOn } from "react-icons/ci";
import { Carousel } from "./commonElements/carousel";
import { TfiRulerAlt2 } from "react-icons/tfi";

export const PropertiesList = (props) => {
  const {
    mapMarkersList,
    activePage = 1,
    searchFilters = {},
    subList = false,
  } = props;
  const pathname = window.location.pathname;
  const navigateToDetailsPage = (marker) => {
    const propertyIdParam = "propertyId=" + marker.id;
    const activePageParam = "&activePage=" + activePage;
    const typeParam = "&propertyType=" + searchFilters.propertyType;
    const bedroomsParam = "&bedrooms=" + searchFilters.bedrooms.min;
    const bathroomsParam = "&bathrooms=" + searchFilters.bathrooms.min;
    const squareFeetParam = (searchFilters.squareFeet && searchFilters.squareFeet.value && searchFilters.squareFeet.value !== "any-any")
    ? `&squareFeet=${searchFilters.squareFeet.value}`
    : "";

    const luxuryParam = pathname === "/luxury-buildings" ? "&luxury=true" : "";
    const newConstructionParam =
      pathname === "/new-construction" ? "&new-construction=true" : "";
    const openHousesParam =
      pathname === "/open-houses" ? "&open-houses=true" : "";
    const neighborhoodParam =
      getNeighborhoodData() === "/neighborhood"
        ? `&neighborhood=${searchFilters.neighborhood}`
        : "";
    window.location.href = `/browse?${propertyIdParam}${activePageParam}${typeParam}${bedroomsParam}${bathroomsParam}${squareFeetParam}${luxuryParam}${newConstructionParam}${openHousesParam}${neighborhoodParam}`;
  };

  const getNeighborhoodData = () => {
    // Use regex to capture the base path and the last number
    return pathname.replace(/\/\d+$/, "");
  };

  return (
    <div
      className={`grid grid-cols-2 md:grid-cols-3 ${
        subList ? "xl:grid-cols-3" : "xl:grid-cols-4"
      } gap-6`}
    >
      {mapMarkersList?.map((marker) => (
        <div
          key={"property" + marker.id}
          className="p-3 rounded-lg cursor-pointer hover:ring-1 hover:ring-inset hover:ring-gray-300 hover:shadow-md"
          onClick={() => navigateToDetailsPage(marker)}
        >
          <div
            className={`h-40 md:h-56 lg:72 ${subList ? "xl:h-44" : "xl:h-80"}`}
          >
            {marker.images.length > 0 ? (
              <Carousel images={marker.images} />
            ) : marker.imageUrl ? (
              <img
                className="w-full h-full rounded-lg object-none"
                src={marker.imageUrl}
                alt="Property"
              />
            ) : (
              "No images available"
            )}
          </div>
          <div
            className={`text-sm ${
              subList ? "lg:text-md" : "lg:text-lg"
            } font-semibold py-1 pb-0`}
          >
            {marker.name}
          </div>
          <div className="flex items-start text-gray-500 py-1">
            <CiLocationOn className="mr-1 mt-1 w-6" />
            <span className="text-xs md:text-sm">{marker.address}</span>
          </div>
          <div className="flex items-center py-2">
            <div className="flex items-center">
              <TfiRulerAlt2 className="text-xs md:text-xl text-cyan-500 " />
              <div className="ml-2 text-xs md:text-md font-semibold">
                {marker.squareFeet}
              </div>
            </div>
            <div className="flex items-center mx-2">
              <MdOutlineKingBed className="text-xs md:text-xl text-cyan-500" />
              <div className="ml-2 text-xs md:text-md font-semibold">
                {marker.bedrooms}
              </div>
            </div>
            <div className="flex items-center mx-2">
              <FaShower className="text-xs md:text-xl text-cyan-500" />
              <div className="ml-2 text-xs md:text-md font-semibold">
                {marker.bathrooms}
              </div>
            </div>
          </div>
          <div className="text-xs md:text-sm">
            Asking price:{" "}
            <span
              className={`text-xs ${
                subList ? "md:text-sm" : "md:text-xl"
              } font-bold`}
            >
              ${numberWithCommas(marker.price)}
            </span>
          </div>
          {/* <div>{marker.description}</div> */}
        </div>
      ))}
    </div>
  );
};
