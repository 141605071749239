import React, { useEffect, useState } from "react";
import "./styles.css"; // Make sure the CSS file exists and is correctly linked
import { FiSearch } from "react-icons/fi"; // Ensure you have react-icons installed and imported correctly

const PageFilters = (props) => {
  const { searchFilters, setSearchFilters, handleFilterApply } = props;
  const [filterApplied, setFilterApplied] = useState(false);

  useEffect(() => {
    if (filterApplied) {
      handleFilterApply();
      setFilterApplied(false);
    }
  }, [filterApplied]);

  const handleFilterChange = (filterName, value) => {
    if (filterName === "propertyType") {
      setSearchFilters((prevFilters) => ({
        ...prevFilters,
        propertyType: value,
        bedrooms: { value: "any-any" },
        bathrooms: { value: "any-any" },
        budget: { value: "any-any" }
      }));
      setFilterApplied(true);
    } else {
      const [min, max] = value.split("-");
      setSearchFilters((prevFilters) => ({
        ...prevFilters,
        [filterName]: { min: min, max: max, value: value },
      }));
    }
  };

  const handleSearchClick = () => {
    setFilterApplied(true);
  };

  return (
    <div className="p-5 bg-white rounded-lg shadow-md">
      <div className="flex flex-wrap justify-start gap-4 mb-5">
        <button
          className={`px-4 py-2 cursor-pointer border-none rounded-md transition-colors duration-300 ${
            searchFilters.propertyType === "buy" ? "bg-[#639bbf] text-white font-bold" : "bg-[#e0e0e0]"
          }`}
          onClick={() => handleFilterChange("propertyType", "buy")}
        >
          Buy
        </button>
        <button
          className={`px-4 py-2 cursor-pointer border-none rounded-md transition-colors duration-300 ${
            searchFilters.propertyType === "rent" ? "bg-[#639bbf] text-white font-bold" : "bg-[#e0e0e0]"
          }`}
          onClick={() => handleFilterChange("propertyType", "rent")}
        >
          Rent
        </button>
      </div>
      <div className="flex gap-4 flex-wrap">
        <div className="flex-1 flex flex-col selector" id="bedrooms">
          <label htmlFor="bedrooms" className="mb-1 text-gray-800 font-semibold">Bedrooms:</label>
          <select
            id="bedrooms"
            value={searchFilters.bedrooms.value}
            onChange={(e) => handleFilterChange("bedrooms", e.target.value)}
          >
            <option value="any-any">Any</option>
            <option value="1-any">1</option>
            <option value="2-any">2</option>
            <option value="3-any">3</option>
            <option value="4-any">4</option>
            <option value="5-any">5+</option>
          </select>
        </div>
        <div className="flex-1 flex flex-col selector" id="bathrooms">
          <label htmlFor="bathrooms" className="mb-1 text-gray-800 font-semibold">Bathrooms:</label>
          <select
            id="bathrooms"
            value={searchFilters.bathrooms.value}
            onChange={(e) => handleFilterChange("bathrooms", e.target.value)}
          >
            <option value="any-any">Any</option>
            <option value="1-any">1</option>
            <option value="2-any">2</option>
            <option value="3-any">3</option>
            <option value="4-any">4</option>
            <option value="5-any">5+</option>
          </select>
        </div>
        <div className="flex-1 flex flex-col selector" id="budget">
          <label htmlFor="budget" className="mb-1 text-gray-800 font-semibold">Budget:</label>
          <select
            id="budget"
            value={searchFilters.budget.value}
            onChange={(e) => handleFilterChange("budget", e.target.value)}
          >
            {searchFilters.propertyType === "buy" ? (
              <>
                <option value="any-any">Any</option>
                <option value="900000-2000000">900K - 2M</option>
                <option value="2100000-5000000">2.1M - 5M</option>
                <option value="5100000-any">5.1M +</option>
              </>
            ) : (
              <>
                <option value="any-any">Any</option>
                <option value="2500-3500">$2,500 - $3,500</option>
                <option value="3500-4500">$3,500 - $4,500</option>
                <option value="4500-any">$4,500+</option>
              </>
            )}
          </select>
        </div>
      </div>
      <button
        className="flex items-center justify-center gap-2 px-4 py-2 mt-5 border-none rounded-lg bg-black text-white font-bold transition-colors duration-300 hover:bg-[#639bbf] w-full"
        onClick={handleSearchClick}>
        <FiSearch /> Filter Results
      </button>
    </div>
  );
};

export default PageFilters;
