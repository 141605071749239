/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../Loading.css"; // Ensure this path matches your project structure

import neighborhoodsData from "../../Assets/neighborhoods.json";
import { getInitialFilterValues } from "../../Utils";
import ListingsPage from "../../components/ListingsPage";
import useListings from "../../hooks/useListings";
import NeighborhoodMap from "./NeighborhoodMap";

const NeighborhoodDetail = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("Overview");
  const [searchFilters, setSearchFilters] = useState(getInitialFilterValues());
  const [activePage, setActivePage] = useState(1);
  const neighborhoodId = location.pathname.split("/").pop();
  const [neighborhoodState, setNeighborhoodState] = useState(0);
  const [neighborhoodObj, setNeighborhoodObj] = useState({});

  const queryParams = `SubdivisionName.eq=${neighborhoodObj?.title || ""}`;

  const { isLoading, mapMarkersList, totalResults } = useListings({
    searchFilters,
    queryParams,
    activePage,
  });

  useEffect(() => {
    setNeighborhoodState(parseInt(neighborhoodId) - 1);
  }, [neighborhoodId]);

  useEffect(() => {
    setNeighborhoodObj(neighborhoodsData[parseInt(neighborhoodState)]);
    setSearchFilters({
      ...searchFilters,
      neighborhood: neighborhoodState,
    });
  }, [neighborhoodState]);

  return (
    <div className="py-2 md:py-5 pb-0 md:pb-0 px-2 md:px-5 h-full overflow-auto overflow-x-hidden">
      {neighborhoodObj && (
        <div
          className="w-full h-80 bg-cover bg-center"
          style={{ backgroundImage: `url(${neighborhoodObj.image})` }}
        >
          <div className="flex items-end h-full bg-black bg-opacity-50">
            <h1 className="text-4xl p-4 w-full text-white font-bold">
              {neighborhoodObj.title}
            </h1>
            <div className="container p-4">
              <div className="flex justify-end">
                <select
                  className="p-3 font-bold border border-gray-300 rounded-lg bg-transparent text-white"
                  value={neighborhoodState}
                  onChange={(e) =>
                    setNeighborhoodState(parseInt(e.target.value))
                  }
                >
                  {neighborhoodsData.map((neighborhood, index) => (
                    <option key={neighborhood.id} value={index}>
                      {neighborhood.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="flex justify-center space-x-4 border-b-2 border-gray-300">
        {["Overview", "Listings", "Map"].map((tab) => (
          <button
            key={tab}
            onClick={() => setActiveTab(tab)}
            className={`py-2 px-4 text-lg font-medium ${
              activeTab === tab
                ? "text-blue-600 border-b-4 border-blue-600"
                : "text-gray-600"
            }`}
          >
            {tab}
          </button>
        ))}
      </div>

      <div className="w-full">
        {activeTab === "Overview" && (
          <div>
            <h2 className="text-2xl font-bold">
              {neighborhoodObj?.titleDetail}
            </h2>
            <p className="mt-4 text-gray-700 leading-relaxed">
              {neighborhoodObj?.description}
            </p>
          </div>
        )}
        {activeTab === "Listings" && (
          <ListingsPage
            title={`${neighborhoodObj?.title} Listings`}
            searchFilters={searchFilters}
            setSearchFilters={setSearchFilters}
            activePage={activePage}
            setActivePage={setActivePage}
            isLoading={isLoading}
            mapMarkersList={mapMarkersList}
            totalResults={totalResults}
          />
        )}
        {activeTab === "Map" && (
          <div className="h-[calc(100vh-460px)]">
            <NeighborhoodMap
              activePage={activePage}
              searchFilters={searchFilters}
              queryParams={queryParams}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default NeighborhoodDetail;
