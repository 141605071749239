import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LoginPage = () => {
  const { loginWithRedirect, logout, isAuthenticated, user } = useAuth0();

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h1 className="text-3xl font-bold mb-6">
        {isAuthenticated ? `Welcome, ${user.name}` : "Welcome to the Login Page"}
      </h1>
      {!isAuthenticated ? (
        <>
          <button
            onClick={() => loginWithRedirect()}
            className="bg-[#639bbf] px-4 py-2 mb-4 text-white font-semibold rounded-md hover:bg-[#02568a]"
          >
            Login
          </button>
        </>
      ) : (
        <>
          <p className="mb-4">You are logged in as {user.name}</p>
          <button
            onClick={() => logout({ returnTo: window.location.origin })}
            className="bg-red-500 px-4 py-2 text-white font-semibold rounded-md hover:bg-red-700"
          >
            Logout
          </button>
        </>
      )}
    </div>
  );
};

export default LoginPage;
