import { filter } from "lodash";

const pathName = window.location.pathname;

export const normalizePropertyData = (property) => {
  return {
    id: property.ListingKey,
    position: [property.Latitude, property.Longitude],
    price: property.ListPrice,
    type: property.PropertySubType,
    bedrooms: property.BedroomsTotal,
    bathrooms: property.BathroomsTotalInteger,
    squareFeet: property.LivingArea,
    imageUrl: property.Media ? property.Media[0].MediaURL : "",
    address: property.UnparsedAddress,
    description: property.PublicRemarks,
    images: filter(property.Media, { MediaCategory: "Photo" }),
    name: property.BuildingName,
    neighborhood: property.City,
  };
};

export const normalizePropertyFilterParams = (filters) => {
  const filterQueries = [];
  if (
    filters.bedrooms.value !== "any-any" &&
    filters.bedrooms.value !== "any"
  ) {
    const [min, max] = filters.bedrooms.value.split("-");
    filterQueries.push(`BedroomsTotal.gte=${min}`);
    if (max !== "any") {
      filterQueries.push(`BedroomsTotal.lte=${max}`);
    }
  }
  if (
    filters.bathrooms.value !== "any-any" &&
    filters.bathrooms.value !== "any"
  ) {
    const [min, max] = filters.bathrooms.value.split("-");
    filterQueries.push(`BathroomsTotalInteger.gte=${min}`);
    if (max !== "any") {
      filterQueries.push(`BathroomsTotalInteger.lte=${max}`);
    }
  }
  if (filters.budget.value !== "any-any") {
    const [min, max] = filters.budget.value.split("-");
    filterQueries.push(`ListPrice.gte=${min}`);
    if (max !== "any") {
      filterQueries.push(`ListPrice.lte=${max}`);
    }
  }
  if (filters.propertyType !== "any" && filters.propertyType !== "any-any") {
    filterQueries.push(
      `MajorChangeType.eq=${
        filters.propertyType === "buy" ? "Closed" : "Rented"
      }`
    );
  }
  if (
    filters.squareFeet.value !== "any" &&
    filters.squareFeet.value !== "any-any"
  ) {
    const [min, max] = filters.squareFeet.value.split("-");
    filterQueries.push(`LivingArea.gte=${min}`);
    if (max !== "any") {
      filterQueries.push(`LivingArea.lte=${max}`);
    }
  }
  return filterQueries.join("&");
};

export const getInitialFilterValues = (propertyTypeProp = "any") => {
  const searchParams = new URLSearchParams(window.location.search);
  const propertyTypeParam = propertyTypeProp
    ? propertyTypeProp
    : searchParams.get("propertyType");
  const bedroomsParam = searchParams.get("bedrooms");
  const bathroomsParam = searchParams.get("bathrooms");
  const budgetParam = searchParams.get("budget");
  const squareFeetParam = searchParams.get("squareFeet");
  const neighborhoodParam = searchParams.get("neighborhoods");
  const filtersObj = {
    neighborhood:
      neighborhoodParam && neighborhoodParam !== "" ? neighborhoodParam : 0,
    squareFeet: {
      min: squareFeetParam ? squareFeetParam : "any",
      max: "any",
      value: `${squareFeetParam ? squareFeetParam : "any"}-any`,
    },
    bedrooms: {
      min: bedroomsParam ? bedroomsParam : "any",
      max: "any",
      value: `${bedroomsParam ? bedroomsParam : "any"}-any`,
    },
    bathrooms: {
      min: bathroomsParam ? bathroomsParam : "any",
      max: "any",
      value: `${bathroomsParam ? bathroomsParam : "any"}-any`,
    },
    budget: {
      min: budgetParam ? budgetParam.split("-")[0] : "any",
      max: budgetParam ? budgetParam.split("-")[1] : "any",
      value: budgetParam ? budgetParam : "any-any",
    },
    propertyType: propertyTypeParam ? propertyTypeParam : "any",
  };
  return filtersObj;
};

export const numberWithCommas = (value) => {
  if (isNaN(value) || value === null) return "";

  const absValue = Math.abs(value);

  if (absValue >= 1_000_000) {
    // Convert to millions (M)
    return (value / 1_000_000).toFixed(1) + "M";
  } else if (absValue >= 1_000) {
    // Convert to thousands (K)
    return (value / 1_000).toFixed(1) + "K";
  } else {
    // Return the number as-is for values less than 1000
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};

export const filterParametersString = (searchFilters) => {
  const propertyTypeParam = `&propertyType=${searchFilters.propertyType}`;
  const minBedroomParam = `&bedrooms=${searchFilters.bedrooms.min}`;
  const minBathroomParam = `&bathrooms=${searchFilters.bathrooms.min}`;
  const budgetParam = `&budget=${searchFilters.budget.min}-${searchFilters.budget.max}`;
  const newConstruction =
    pathName === "/new-construction" ? "&NewConstructionYN.eq=true" : "";
  return (
    propertyTypeParam +
    minBedroomParam +
    minBathroomParam +
    budgetParam +
    newConstruction
  );
};
