/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "../Loading.css"; // Ensure this path matches your project structure

import MapView from "../../components/MapView"; // Adjust the path based on your file structure
import useListings from "../../hooks/useListings";
import CircularLoading from "../../components/CircularLoading";
import Leaflet from "leaflet";
import { findTopThreeClosestLocations } from "../../Utils/closest-distance";
import { filterParametersString } from "../../Utils";
import PropertyDetailsModal from "../../components/modals/propertyDetailsModal";

const NeighborhoodMap = (props) => {
  const { activePage, searchFilters, queryParams } = props;

  const [map, setMap] = useState();
  const [selectedProperty, setSelectedProperty] = useState(null);

  const { isLoading, mapMarkersList } = useListings({
    searchFilters,
    queryParams,
    activePage,
    limit: 100,
  });

  const initialLoad = useRef(true);

  useEffect(() => {
    if (mapMarkersList.length && map) {
      const bounds = Leaflet.latLngBounds(
        mapMarkersList.map((prop) => prop.position)
      );
      map.fitBounds(bounds, { padding: [50, 50] });
      initialLoad.current = false; // Disable further auto-zoom on subsequent updates
    }
  }, [map, mapMarkersList]);

  const onShowDetail = (propertyId) => {
    const property = mapMarkersList.find((prop) => prop.id === propertyId);
    setSelectedProperty(property);
  };

  return (
    <>
      {isLoading ? (
        <CircularLoading />
      ) : (
        <MapView
          searchFilters={searchFilters}
          isOnHomePage={false}
          mapMarkersList={mapMarkersList}
          setSelectedProperty={setSelectedProperty}
          selectedProperty={selectedProperty}
          onShowDetail={onShowDetail}
          map={map}
          setMap={setMap}
        />
      )}
      {selectedProperty && (
        <PropertyDetailsModal
          filterParametersString={filterParametersString(searchFilters)}
          searchFilters={searchFilters}
          property={selectedProperty}
          onClose={() => setSelectedProperty(null)}
          similarProperties={findTopThreeClosestLocations(
            selectedProperty,
            mapMarkersList
          )}
        />
      )}
    </>
  );
};

export default NeighborhoodMap;
