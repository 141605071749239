import { normalizePropertyData, normalizePropertyFilterParams } from "../Utils";

export const getListings = async (filterParams = "", flags = "") => {
  const data = listingsApi(normalizePropertyFilterParams(filterParams), flags);
  return data;
};

export const getOpenHouses = async (filterParams = "", flags = "") => {
  const data = openHousesApi(
    normalizePropertyFilterParams(filterParams),
    flags
  );
  return data;
};

const listingsApi = async (filterParams = "", flags = "") => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_API}/miamire/listings?access_token=${process.env.REACT_APP_ACCESS_TOKEN}&${filterParams}&${flags}`
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const formattedData = await response.json();
    const normalizedData = formattedData.bundle.map((item) => {
      return normalizePropertyData(item);
    });
    return { total: formattedData.total, properties: normalizedData };
  } catch (error) {
    console.error("Error fetching listings", error);
    return [];
  }
};

const openHousesApi = async (filterParams = "", flags = "") => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_API}/miamire/openhouses?access_token=${process.env.REACT_APP_ACCESS_TOKEN}&limit=200&${filterParams}&${flags}`
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const formattedData = await response.json();
    const normalizedData = formattedData.bundle.map((item) => {
      return normalizePropertyData(item);
    });
    return normalizedData;
  } catch (error) {
    console.error("Error fetching open houses", error);
    return [];
  }
};
