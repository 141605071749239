//  Haversine formula to calculate distance between two coordinates (in km)
function getDistanceFromLatLon(lat1, lon1, lat2, lon2) {
  const R = 6371; // Radius of the Earth in kilometers
  const dLat = deg2rad(lat2 - lat1); // delta latitude in radians
  const dLon = deg2rad(lon2 - lon1); // delta longitude in radians
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in kilometers
  return distance;
}

// Convert degrees to radians
function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

// Function to find the top 3 closest elements based on position
export function findTopThreeClosestLocations(targetElement, locationsArray) {
  const targetPosition = targetElement.position;

  // Create an array of objects with distance and location
  const distances = locationsArray
    .filter((element) => element !== targetElement) // Exclude target element
    .map((element) => {
      const elementPosition = element.position;
      const distance = getDistanceFromLatLon(
        targetPosition[0],
        targetPosition[1],
        elementPosition[0],
        elementPosition[1]
      );
      return { ...element, distance }; // Add distance to the element object
    });

  // Sort by distance (ascending)
  distances.sort((a, b) => a.distance - b.distance);

  // Return the top 3 closest locations
  return distances.slice(0, 3);
}
