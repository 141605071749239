import React, { useState } from "react";
import { getInitialFilterValues } from "../../Utils";
import ListingsPage from "../../components/ListingsPage";
import useListings from "../../hooks/useListings";

const NewConstruction = () => {
  const [searchFilters, setSearchFilters] = useState(getInitialFilterValues());
  const [activePage, setActivePage] = useState(1);

  const { isLoading, mapMarkersList, totalResults } = useListings({
    searchFilters,
    queryParams: "NewConstructionYN.eq=true",
    activePage,
  });

  return (
    <ListingsPage
      title="New Construction"
      searchFilters={searchFilters}
      setSearchFilters={setSearchFilters}
      activePage={activePage}
      setActivePage={setActivePage}
      isLoading={isLoading}
      mapMarkersList={mapMarkersList}
      totalResults={totalResults}
    />
  );
};

export default NewConstruction;
