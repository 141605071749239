import { PropertiesList } from "../../PropertiesList";

export const SuggestedList = (props) => {
  const { similarProperties, searchFilters } = props;
  return (
    <div className="w-full border-t-2 ">
      <div className="pt-2 font-bold text-center">Other Listings</div>
      <div className="flex justify-center items-center">
        <PropertiesList
          mapMarkersList={similarProperties}
          subList={true}
          searchFilters={searchFilters}
        />
      </div>
    </div>
  );
};
