import React, { useState } from "react";
import { FaArrowLeft, FaShareAlt } from "react-icons/fa";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { PropertyDetailsComponent } from "./PropertyDetialsComponent";
import { SuggestedList } from "./SuggestedList";
import EmbeddedContactPage from "./EmbeddedContactPage";
import { MdClose } from "react-icons/md";

const PropertyDetailsModal = (props) => {
  const {
    property,
    onClose,
    filterParametersString,
    similarProperties,
    searchFilters,
  } = props;
  const [showDetailsTab, setShowDetailsTab] = useState(true);

  const copyToClipboard = () => {
    const urlToShare = `${window.location.origin}/browse?propertyId=${property.id}${filterParametersString}`;
    navigator.clipboard.writeText(urlToShare).then(
      () => {
        alert("Link copied to clipboard!");
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  if (!property) return null;

  return (
    <div
      className="fixed top-0 left-0 w-full h-full flex justify-center lg:justify-end items-end lg:items-center z-[1000] bg-gray-600/20"
      onClick={onClose}
    >
      <div
        className="h-full w-full lg:w-3/5 flex items-center"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="h-[85%] w-full bg-white p-8 rounded-md relative z-50">
          {showDetailsTab ? (
            <>
              <button
                className="absolute top-0 md:top-2 right-10 md:right-14 text-gray-400 flex justify-center items-center mt-1"
                onClick={copyToClipboard}
              >
                <FaShareAlt className="mr-1 " /> Share
              </button>
              <MdClose
                className="absolute top-0 md:top-2 right-0 md:right-2 text-3xl rounded-full text-gray-400 hover:text-gray-100 hover:bg-gray-300 cursor-pointer"
                onClick={onClose}
              />
              <div className="h-full grid grid-rows-5 grid-flow-col">
                <div className="row-span-5 lg:row-span-3">
                  <PropertyDetailsComponent
                    property={property}
                    showContactForm={() => setShowDetailsTab(false)}
                  />
                </div>
                <div className="hidden lg:block row-span-2">
                  <SuggestedList
                    similarProperties={similarProperties}
                    searchFilters={searchFilters}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <FaArrowLeft
                className="absolute left-10 top-10"
                onClick={() => setShowDetailsTab(true)}
              />
              <div className="p-0 sm:p-10 h-full">
                <EmbeddedContactPage />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PropertyDetailsModal;
